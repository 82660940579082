import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <a
        style={{
          color: "white",
        }}
      >
        support@marinavita.com
      </a>
      <div>© 2024 MarinaVita, Inc. All Rights Reserved</div>
      <a
        onClick={() => navigate("/termsandconditions")}
        style={{
          color: "white",
        }}
      >
        Terms and Conditions
      </a>
    </FooterContainer>
  );
};
export default Footer;

const FooterContainer = styled.div`
  display: flex;
  background-color: black;
  justify-content: space-around;
  padding: 20px;
  a {
    padding: 5px;
  }
  div {
    padding: 5px;
  }
`;
