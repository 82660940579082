import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import { FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";
import { Icon } from "@rsuite/icons";
import boatImage from "../assets/boat.jpg";
//import logo from "../assets/logo.png";
import logoWhite from "../assets/logowhite.jpg";
import MovingComponent from "react-moving-text";

const AnimationsForChaining = ["pulse", "flash"];

const ComingSoonPage = () => {
  const navigate = useNavigate();

  const routeChange = () => {
    let path = `https://docs.google.com/forms/d/e/1FAIpQLSc-rBJTl4kQitBT-YnQKo-ksnQ2jiSGeIdEdVGQkZVn1-YUlA/viewform?usp=sf_link`;
    window.open(path, "_blank");
  };

  const routeChangeToInstagram = () => {
    let path = `https://www.instagram.com/marinavita_official?igsh=MTV5bDJpbGZhazV6&utm_source=qr`;
    window.open(path, "_blank");
  };
  const routeChangeToTiktok = () => {
    let path = `https://www.tiktok.com/@marinavitaofficial?_t=8ol9oE0lt68&_r=1`;
    window.open(path, "_blank");
  };
  const routeChangeToTwitter = () => {
    let path = `https://x.com/mvitaofficial?s=11&t=7oLPyWoHhm5dq8b0OYj_kA`;
    window.open(path, "_blank");
  };

  const [animationIndex, setAnimationIndex] = useState(0);
  const [animationType, setAnimationType] = useState(AnimationsForChaining[0]);

  const handleChainAnimation = () => {
    setAnimationIndex(animationIndex + 1);
    setAnimationType(AnimationsForChaining[animationIndex + 1]);
  };
  return (
    <CustomContainer>
      <LeftSide>
        <div
          style={{
            height: "100vh",
            width: "100%",
            backgroundImage: `url(${boatImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Overlay>
            <Content>
              <ButtonHeader>
                <Button
                  style={{
                    float: "left",
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                  color="red"
                >
                  Home
                </Button>
                <Button
                  onClick={() => navigate("/about")}
                  style={{
                    float: "left",
                    marginLeft: "20px",
                    marginTop: "20px",
                    color: "white",
                    borderColor: "white",
                  }}
                  appearance="ghost"
                >
                  About
                </Button>
              </ButtonHeader>

              <LogoContainer
              // style={{
              //   backgroundImage: `url(${logo})`,
              // }}
              >
                <div
                  style={{
                    height: "100px",
                    width: "300px",
                    backgroundImage: `url(${logoWhite})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                ></div>
              </LogoContainer>
            </Content>
          </Overlay>
        </div>
      </LeftSide>
      <RightSide>
        {/* <Text>Great things are coming </Text> */}
        <MiddleContainer>
          <Text>
            <MovingComponent
              onAnimationEnd={handleChainAnimation}
              type={animationType}
              duration="1000ms"
              timing="linear"
              fillMode="forwards"
              iteration={"infinite"}
            >
              GET €30 OFF
            </MovingComponent>
          </Text>
          <br></br>
          <p>Fill the form to get your €30 off discount code.</p>
          <br></br>
          <ul>
            <li>Redeemable at all bookings in Malta</li>
            <li>Not valid with any other discounts or promotions</li>
            <li>Not redeemable with cash</li>
          </ul>
          <Button
            onClick={routeChange}
            color="red"
            style={{
              width: "100%",
              fontSize: "20px",
            }}
          >
            Claim Voucher
          </Button>
          <div>
            <Icon
              onClick={routeChangeToInstagram}
              style={{ padding: "5px" , cursor:"pointer"}}
              as={FaInstagram}
              size="0.8em"
              color="white"
            />
            <Icon
              onClick={routeChangeToTwitter}
              style={{ padding: "5px" , cursor:"pointer"}}
              as={FaTwitter}
              size="0.8em"
              color="white"
            />
            <Icon
              onClick={routeChangeToTiktok}
              style={{ padding: "5px" , cursor:"pointer"}}
              as={FaTiktok}
              size="0.8em"
              color="white"
            />
          </div>
        </MiddleContainer>
      </RightSide>
    </CustomContainer>
  );
};
export default ComingSoonPage;

const CustomContainer = styled.div`
  display: flex;
  height: 100vh;
  @media only screen and (max-width: 1000px) {
    display: block;
    height: 100%;
  }
`;
const LeftSide = styled.div`
  width: 100%;
  background: white;
  text-align: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Adjust the RGBA values to change the color and opacity */
  z-index: 1;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const RightSide = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0d352b;
  text-align: center;
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  color: white; /* For readability */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
`;
const ButtonHeader = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  color: white; /* For readability */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: left;
`;
const MiddleContainer = styled.div`
  border: 1px solid white;
  width: 40%;
  padding: 30px;
  margin: auto;
  font-size: 40px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  p {
    color: white;
    font-size: x-large;
  }
  br {
    content: "";
    margin: 2em;
    display: block;
    font-size: 24%;
    outline: red;
  }
  ul {
    color: white;
    font-size: large;
    text-align: left;
  }
  @media only screen and (max-width: 1000px) {
    margin: 50px;
  }
  @media only screen and (max-width: 700px) {
    width: 90%;
  }
`;
const LogoContainer = styled.div`
  margin: auto;
  font-size: 40px;
  text-align: center;
  height: "100px";
  width: "100px";

  border-style: solid;
  border-color: white;
  // padding: 5px 40px;
`;

const Text = styled.div`
  font-size: larger;

  color: white;
`;
