import "./App.css";
import React from 'react';
import ComingSoonPage from "./pages/comingsoon";
import Footer from "./pages/footer";
import TermsAndConditionsPage from "./pages/terms";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutPage from "./pages/about";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<ComingSoonPage />} />
          <Route path="/termsandconditions" element={<TermsAndConditionsPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
