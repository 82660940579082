import React, { useEffect } from "react";
import { Button } from "rsuite";
import {
    useNavigate,
  } from "react-router-dom";
import styled from "styled-components";
const TermsAndConditionsPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
       <ButtonHeader>
        <Button
          onClick={() =>
            navigate("/")}
          style={{
            float: "left",
            marginLeft: "20px",
            marginTop: "20px",
            color: "white",
            borderColor: "white",
          }}
          appearance="ghost"
        >
          Home
        </Button>
        <Button
         onClick={() =>
          navigate("/about")}
          style={{
            float: "left",
            marginLeft: "20px",
            marginTop: "20px",
            color: "white",
            borderColor: "white",
          }}
          appearance="ghost"
        >
          About
        </Button>
      </ButtonHeader>
    <AboutContainer>
    <h2>Terms and Conditions</h2>
    <br />
    <h3>1. Introduction</h3>
    <br />
    <p>
      Welcome to the demo website of MarinaVita! These Terms and Conditions (&quot;Terms&quot;) govern your use of our demo website and the collection of data for market research purposes. By accessing or using our demo website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you should not use our demo website.
    </p>
    <br />
    <h3>2. Definitions</h3>
    <br />
    <p>
      &quot;We,&quot; &quot;Us,&quot; &quot;Our&quot;: Refers to MarinaVita, the operator of this demo website. &quot;User,&quot; &quot;You,&quot; &quot;Your&quot;: Refers to any individual or entity using our demo website. &quot;Services&quot;: Refers to the market research activities conducted through this demo website.
    </p>
    <br />
    <h3>3. Purpose of the Demo Website</h3>
    <br />
    <p>
      The primary purpose of this demo website is to collect data for market research to improve our services. This data will help us understand user preferences and behaviors to enhance our future offerings.
    </p>
    <br />
    <h3>4. User Accounts</h3>
    <br />
    <p>
      4.1 Account Creation: To participate in our market research, you may be required to create an account. You agree to provide accurate, complete, and current information during the registration process.
    </p>
    <br />
    <p>
      4.2 Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
    </p>
    <br />
    <h3>5. Data Collection and Use</h3>
    <br />
    <p>
      5.1 Types of Data Collected: We may collect personal data such as your name, email address, and other information you provide through forms on our demo website.
    </p>
    <br />
    <p>
      5.2 Purpose of Data Collection: The data collected will be used solely for market research purposes to improve our services and will not be shared with third parties without your consent.
    </p>
    <br />
    <p>
      5.3 Consent: By providing your personal data, you consent to its use for market research purposes as described in these Terms and our Privacy Policy.
    </p>
    <br />
    <h3>6. User Conduct</h3>
    <br />
    <p>
      6.1 Prohibited Activities: You agree not to engage in any of the following activities:
    </p>
    <br />
    <p>
      Violating any local, state, national, or international law or regulation. Using our demo website for any illegal or unauthorized purpose. Interfering with or disrupting the security or integrity of our demo website or services. Impersonating any person or entity or falsely representing your affiliation with any person or entity.
    </p>
    <br />
    <p>
      6.2 Content: You are solely responsible for any content you post or submit on our demo website. You agree not to post any content that is unlawful, defamatory, obscene, or otherwise objectionable.
    </p>
    <br />
    <h3>7. Intellectual Property</h3>
    <br />
    <p>
      7.1 Ownership: All content and materials on our demo website, including text, graphics, logos, and software, are the property of MarinaVita or its licensors and are protected by intellectual property laws.
    </p>
    <br />
    <p>
      7.2 License: MarinaVita grants you a limited, non-exclusive, non-transferable, and revocable license to access and use our demo website for personal and non-commercial purposes.
    </p>
    <br />
    <h3>8. Limitation of Liability</h3>
    <br />
    <p>
      To the maximum extent permitted by law, MarinaVita and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our demo website or services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; and (iii) any interruption or cessation of transmission to or from our demo website.
    </p>
    <br />
    <h3>9. Indemnification</h3>
    <br />
    <p>
      You agree to indemnify and hold MarinaVita, its affiliates, and their respective officers, directors, employees, and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees, arising out of or in any way connected with your use of our demo website or services, your violation of these Terms, or your violation of any rights of another person or entity.
    </p>
    <br />
    <h3>10. Compliance with Sanctions</h3>
    <br />
    <p>
      You acknowledge and agree that MarinaVita is subject to various international laws and regulations, including those that impose sanctions and embargoes on certain countries and individuals. As a result, we do not provide access to our website or services to individuals or entities located in or associated with countries subject to sanctions or embargoes. By using our website, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country.
    </p>
    <br />
    <h3>11. GDPR Compliance</h3>
    <br />
    <p>
      11.1 Data Protection: We comply with the General Data Protection Regulation (GDPR) when collecting and processing personal data from users located in the European Union.
    </p>
    <br />
    <p>
      11.2 User Rights: You have the right to access, rectify, or erase your personal data, as well as the right to restrict or object to the processing of your data. You also have the right to data portability. To exercise these rights, please contact us at info@marinavita.com.
    </p>
    <br />
    <p>
      11.3 Data Security: We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including measures to protect your personal data from unauthorized access, use, or disclosure.
    </p>
    <br />
    <p>
      11.4 Data Retention: We retain personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by law.
    </p>
    <br />
    <h3>12. Modifications to Terms</h3>
    <br />
    <p>
      We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our demo website. Your continued use of our demo website following the posting of changes constitutes your acceptance of such changes.
    </p>
    <br />
    <h3>13. Governing Law</h3>
    <br />
    <p>
      These Terms shall be governed by and construed in accordance with the laws of Malta, without regard to its conflict of laws principles.
    </p>
    <br />
    <h3>14. Contact Us</h3>
    <br />
    <p>
      If you have any questions or concerns about these Terms, please contact us at:
    </p>
    <br />
    <p>MarinaVita</p>
    <a href="mailto:info@marinavita.com">Email: info@marinavita.com</a>
    <br />
    <p>
      By using our demo website and services, you agree to these Terms and Conditions. Thank you for participating in our market research!
    </p>
      
    </AboutContainer>
    </Container>
  );
};
export default TermsAndConditionsPage;

const AboutContainer = styled.div`
  background-color: black;
  justify-content: space-around;
  padding: 20px;
  height: 100%;
  text-align: center;

  padding: 50px;
  color: white;
`;
const Container = styled.div`
  background-color: black;
  justify-content: space-around;
  height: 100%;
  text-align: center;

  color: white;
`;


const ButtonHeader = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  color: white; /* For readability */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: left;
`;
