import React, { useEffect } from "react";
import { Button } from "rsuite";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const AboutPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <ButtonHeader>
        <Button
          onClick={() => navigate("/")}
          style={{
            float: "left",
            marginLeft: "20px",
            marginTop: "20px",
            color: "white",
            borderColor: "white",
          }}
          appearance="ghost"
        >
          Home
        </Button>
        <Button
          style={{
            float: "left",
            marginLeft: "20px",
            marginTop: "20px",
            color: "white",
            borderColor: "white",
          }}
          appearance="ghost"
        >
          About
        </Button>
      </ButtonHeader>
      <AboutContainer>
        <AboutHeader>
          <h1>About Us</h1>
          <h3>MarinaVita</h3>
          <p>
            Welcome to MarinaVita! We are dedicated to providing exceptional
            boat rental experiences in Malta. Our demo website is designed to
            gather valuable feedback from users like you to help us refine our
            services and deliver the best maritime adventures.
          </p>
        </AboutHeader>
        <AboutWhite>
          <h2>Our Mission</h2>
          <p>
            At MarinaVita, our mission is to make the joy of boating accessible
            to everyone. We aim to provide safe, affordable, and unforgettable
            boat rental experiences that allow our customers to explore the
            beauty of Malta’s coastline and waters.
          </p>
        </AboutWhite>
        <AboutBlack className="list">
          <h2>Our Values</h2>
          <p>
            Customer Focus: Our customers are at the heart of everything we do.
            We strive to exceed their expectations in every interaction.
          </p>
          <p>
            Safety First: We prioritize the safety of our customers by
            maintaining our fleet to the highest standards and ensuring all
            safety regulations are met.
          </p>
          <p>
            Sustainability: We are committed to protecting the marine
            environment by promoting sustainable boating practices.
          </p>
          <p>
            Excellence: We continuously seek to improve our services and deliver
            exceptional experiences.
          </p>
        </AboutBlack>
        <AboutWhite>
          <h2>Our Story</h2>
          <p>
            MarinaVita was founded by a group of boating enthusiasts who wanted
            to share their love for the sea with others. Recognizing the need
            for a reliable and customer-focused boat rental service in Malta, we
            launched MarinaVita to fill this gap. Our demo website is an
            essential step in our journey, allowing us to gather insights and
            improve our offerings based on real user feedback.
          </p>
        </AboutWhite>
        <AboutBlack>
          <h2>Our Services</h2>
          <p>
            MarinaVita offers a variety of boat rental options, from small boats
            for a day trip to luxury yachts for an extended stay. Whether you’re
            looking to explore the coastline, enjoy water sports, or relax on
            the open sea, we have the perfect boat for you. Our demo website
            showcases these services, and your feedback will help us enhance
            them.
          </p>
        </AboutBlack>
        <AboutContact>
          <h4>Contact Information</h4>
          <p>
            We’d love to hear from you! If you have any questions, feedback, or
            suggestions, please don’t hesitate to reach out to us.
          </p>
          <p>Email: info@marinavita.com</p>
          <p>Phone: +356 997 99 681</p>
          <p>Address: MarinaVita, 123 Maritime Lane, Valletta, Malta</p>
        </AboutContact>
      </AboutContainer>
    </Container>
  );
};
export default AboutPage;

const AboutHeader = styled.div`
  background: #0d352b;
  height: 500px;

  padding: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  p {
    width: 700px;
  }
  @media only screen and (max-width: 1000px) {
    p {
      width: 100%;
    }
  }
`;
const AboutContact = styled.div`
background-color: white;
height: 200px;

  padding: 50px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  @media only screen and (max-width: 1000px) {
    p {
      width: 100% !important;
    }
  }
  p {
    font-size: medium !important;
    text-align: end;

    width: 700px;
  }
`;
const AboutWhite = styled.div`
  background-color: white;
  height: 500px;

  padding: 50px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: end;

  justify-content: space-evenly;
  p {
    text-align: end;

    width: 700px;
  }
  @media only screen and (max-width: 1000px) {
    p {
      width: 100%;
    }
  }
`;
const AboutBlack = styled.div`
  background: #0d352b;

  height: 500px;

  padding: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  p {
    width: 700px;
  }
  @media only screen and (max-width: 1000px) {
    p {
      width: 100%;
    }
  }

`;
const AboutContainer = styled.div`
  height: 100%;

  color: white;
  p {
    font-size: x-large;
  }
  @media only screen and (max-width: 1000px) {
    p {
      font-size: large;
    }
  }
`;
const Container = styled.div`
  height: 100%;

  color: white;
`;


const ButtonHeader = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  color: white; /* For readability */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: left;
  background: #0d352b;
`;
